<template>
  <div>
    <Card>
      <template #title>Список пользователей</template>
      <template #content>
        <AbstractTable ref="table" :expander="$store.state.user.role === 'admin'" code="users" display-filter="menu">
          <template #role="row">
            {{ compendiumService.getValueByCode($store.state.compendiums.userRole, row.data.role) }}
          </template>
          <template #isBanned="row">
            {{ row.data.isBanned ? 'Заблокирован' : 'Активен' }}
          </template>
          <template #expand="{row}">
            <Button
              v-if="!row.data.isBanned"
              class="p-button-danger"
              :loading="deletingUser"
              label="Заблокировать"
              icon="pi pi-ban"
              @click="deleteUser(row.data.id)"
            ></Button>
            <p v-if="row.data.isBanned">Нет доступных действий</p>
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import CompendiumService from "@/service/CompendiumService";
import CRUDService from "@/service/CRUDService";
import ResponseService from "@/service/ResponseService";
export default {
  name: "UsersTable",
  components: {AbstractTable},
  data() {
    return {
      deletingUser: false,
      compendiumService: CompendiumService,
    }
  },
  methods: {
    deleteUser(id) {
      this.deletingUser = true
      CRUDService.delete('user', id).then((res) => {
        this.$toast.add({
          severity: 'success',
          summary: 'Пользователь заблокирован',
          detail: 'Пользователь с ID ' + res.id + ' заблокирован',
          life: 2000
        })
        this.$refs.table.getData()
        this.deletingUser = false
      }).catch((err) => {
        ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
        this.deletingUser = false
      })
    }
  },
}
</script>

<style scoped>

</style>