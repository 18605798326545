<template>
  <div class="grid">
    <div class="col-12 lg:col-4" v-if="$store.state.user.role === 'admin'">
      <CreateUser></CreateUser>
    </div>
    <div class="col-12 lg:col-8">
      <UsersTable></UsersTable>
    </div>
  </div>
</template>

<script>
import UsersTable from "@/components/tables/UsersTable";
import CreateUser from "@/components/CreateUser";
export default {
  name: "Users",
  components: {CreateUser, UsersTable}
}
</script>

<style scoped>

</style>