<template>
  <div>
    <Card>
      <template #title>Создание пользователя</template>
      <template #content>
        <AbstractForm
          ref="form"
          :loading="loading"
          v-model="createUserForm"
          :on-commit="createUser"
          commit-label="Создать пользователя"
          commit-icon="pi pi-plus"
        ></AbstractForm>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm";
import FormModel from "@/model/FormModel";
import CRUDService from "@/service/CRUDService";
import ResponseService from "@/service/ResponseService";
export default {
  name: "CreateUser",
  components: {AbstractForm},
  data() {
    return {
      loading: false,
      createUserForm: {
        email: new FormModel('E-mail'),
        surname: new FormModel('Фамилия'),
        name: new FormModel('Имя'),
        patronymic: new FormModel('Отчество'),
        role: new FormModel('Роль', {
          type: 'select',
          selectOptions: this.$store.state.compendiums.userRole,
          selectOption: 'value',
          selectValue: 'code'
        }),
      }
    }
  },
  methods: {
    createUser() {
      this.loading = true
      CRUDService.create('user', {
        email: this.createUserForm.email.value,
        surname: this.createUserForm.surname.value,
        name: this.createUserForm.name.value,
        patronymic: this.createUserForm.patronymic.value,
        role: this.createUserForm.role.value,
      }).then((res) => {
        this.$toast.add({
          severity: 'success',
          summary: 'Пользователь создан',
          detail: 'Создан пользователь с id ' + res.id,
          life: 2000
        })
        this.$refs.form.clear()
        this.loading = false
      }).catch((err) => {
        ResponseService.handleErrorResponseWithForm(err, this.createUserForm, this.$toast)
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>